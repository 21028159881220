.custom-table .ant-table-body {
  overflow-y: auto !important;
  max-height: 240px !important;
}

.custom-table .ant-table-body::-webkit-scrollbar {
  width: 6px;
}

.custom-table .ant-table-body::-webkit-scrollbar-thumb {
  background-color: #d9d9d9;
  border-radius: 3px;
}

.custom-table .ant-table-body::-webkit-scrollbar-thumb:hover {
  background-color: #bfbfbf;
}

.custom-table .ant-table-body::-webkit-scrollbar-track {
  background-color: #f5f5f5;
  border-radius: 3px;
}

/* For Firefox */
.custom-table .ant-table-body {
  scrollbar-width: thin;
  scrollbar-color: #d9d9d9 #f5f5f5;
}

/* Override Ant Design's default styles */
.custom-table .ant-table-header {
  overflow: hidden !important;
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
}

.custom-table .ant-table-body {
  overflow: auto scroll !important;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.custom-table .ant-table-header::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.custom-table .ant-table-header {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
